// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-crops-js": () => import("./../src/pages/crops.js" /* webpackChunkName: "component---src-pages-crops-js" */),
  "component---src-pages-diseases-bacterial-diseases-js": () => import("./../src/pages/diseases/bacterial-diseases.js" /* webpackChunkName: "component---src-pages-diseases-bacterial-diseases-js" */),
  "component---src-pages-diseases-fungal-diseases-js": () => import("./../src/pages/diseases/fungal-diseases.js" /* webpackChunkName: "component---src-pages-diseases-fungal-diseases-js" */),
  "component---src-pages-diseases-js": () => import("./../src/pages/diseases.js" /* webpackChunkName: "component---src-pages-diseases-js" */),
  "component---src-pages-diseases-oomycetes-diseases-js": () => import("./../src/pages/diseases/oomycetes-diseases.js" /* webpackChunkName: "component---src-pages-diseases-oomycetes-diseases-js" */),
  "component---src-pages-diseases-seed-borne-diseases-js": () => import("./../src/pages/diseases/seed-borne-diseases.js" /* webpackChunkName: "component---src-pages-diseases-seed-borne-diseases-js" */),
  "component---src-pages-diseases-virus-diseases-js": () => import("./../src/pages/diseases/virus-diseases.js" /* webpackChunkName: "component---src-pages-diseases-virus-diseases-js" */),
  "component---src-pages-hydroponics-js": () => import("./../src/pages/hydroponics.js" /* webpackChunkName: "component---src-pages-hydroponics-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrated-management-js": () => import("./../src/pages/integrated-management.js" /* webpackChunkName: "component---src-pages-integrated-management-js" */),
  "component---src-pages-neonicatinanoids-js": () => import("./../src/pages/neonicatinanoids.js" /* webpackChunkName: "component---src-pages-neonicatinanoids-js" */),
  "component---src-pages-nutrition-boron-js": () => import("./../src/pages/nutrition/boron.js" /* webpackChunkName: "component---src-pages-nutrition-boron-js" */),
  "component---src-pages-nutrition-calcium-js": () => import("./../src/pages/nutrition/calcium.js" /* webpackChunkName: "component---src-pages-nutrition-calcium-js" */),
  "component---src-pages-nutrition-copper-js": () => import("./../src/pages/nutrition/copper.js" /* webpackChunkName: "component---src-pages-nutrition-copper-js" */),
  "component---src-pages-nutrition-iron-js": () => import("./../src/pages/nutrition/iron.js" /* webpackChunkName: "component---src-pages-nutrition-iron-js" */),
  "component---src-pages-nutrition-js": () => import("./../src/pages/nutrition.js" /* webpackChunkName: "component---src-pages-nutrition-js" */),
  "component---src-pages-nutrition-magnesium-js": () => import("./../src/pages/nutrition/magnesium.js" /* webpackChunkName: "component---src-pages-nutrition-magnesium-js" */),
  "component---src-pages-nutrition-manganese-js": () => import("./../src/pages/nutrition/manganese.js" /* webpackChunkName: "component---src-pages-nutrition-manganese-js" */),
  "component---src-pages-nutrition-molybdenum-js": () => import("./../src/pages/nutrition/molybdenum.js" /* webpackChunkName: "component---src-pages-nutrition-molybdenum-js" */),
  "component---src-pages-nutrition-nitrogen-js": () => import("./../src/pages/nutrition/nitrogen.js" /* webpackChunkName: "component---src-pages-nutrition-nitrogen-js" */),
  "component---src-pages-nutrition-phosphorus-js": () => import("./../src/pages/nutrition/phosphorus.js" /* webpackChunkName: "component---src-pages-nutrition-phosphorus-js" */),
  "component---src-pages-nutrition-potassium-js": () => import("./../src/pages/nutrition/potassium.js" /* webpackChunkName: "component---src-pages-nutrition-potassium-js" */),
  "component---src-pages-nutrition-sulphur-js": () => import("./../src/pages/nutrition/sulphur.js" /* webpackChunkName: "component---src-pages-nutrition-sulphur-js" */),
  "component---src-pages-nutrition-template-js": () => import("./../src/pages/nutrition/template.js" /* webpackChunkName: "component---src-pages-nutrition-template-js" */),
  "component---src-pages-nutrition-zinc-js": () => import("./../src/pages/nutrition/zinc.js" /* webpackChunkName: "component---src-pages-nutrition-zinc-js" */),
  "component---src-pages-pests-js": () => import("./../src/pages/pests.js" /* webpackChunkName: "component---src-pages-pests-js" */),
  "component---src-pages-rice-js": () => import("./../src/pages/rice.js" /* webpackChunkName: "component---src-pages-rice-js" */),
  "component---src-pages-soil-js": () => import("./../src/pages/soil.js" /* webpackChunkName: "component---src-pages-soil-js" */),
  "component---src-pages-soil-soil-composition-soil-mineral-matter-js": () => import("./../src/pages/soil/soilComposition/soilMineralMatter.js" /* webpackChunkName: "component---src-pages-soil-soil-composition-soil-mineral-matter-js" */),
  "component---src-pages-soil-soil-composition-soil-organic-matter-js": () => import("./../src/pages/soil/soilComposition/soilOrganicMatter.js" /* webpackChunkName: "component---src-pages-soil-soil-composition-soil-organic-matter-js" */),
  "component---src-pages-soil-soil-composition-soil-water-and-air-js": () => import("./../src/pages/soil/soilComposition/soilWaterAndAir.js" /* webpackChunkName: "component---src-pages-soil-soil-composition-soil-water-and-air-js" */),
  "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-add-beneficial-organisms-js": () => import("./../src/pages/soil/soilFertilityProductivityAndHealth/managementPractices/addBeneficialOrganisms.js" /* webpackChunkName: "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-add-beneficial-organisms-js" */),
  "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-increase-organic-matter-inputs-js": () => import("./../src/pages/soil/soilFertilityProductivityAndHealth/managementPractices/increaseOrganicMatterInputs.js" /* webpackChunkName: "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-increase-organic-matter-inputs-js" */),
  "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-manage-nutrients-js": () => import("./../src/pages/soil/soilFertilityProductivityAndHealth/managementPractices/manageNutrients.js" /* webpackChunkName: "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-manage-nutrients-js" */),
  "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-reduce-pesticide-use-js": () => import("./../src/pages/soil/soilFertilityProductivityAndHealth/managementPractices/reducePesticideUse.js" /* webpackChunkName: "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-reduce-pesticide-use-js" */),
  "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-reduce-soil-compaction-js": () => import("./../src/pages/soil/soilFertilityProductivityAndHealth/managementPractices/reduceSoilCompaction.js" /* webpackChunkName: "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-reduce-soil-compaction-js" */),
  "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-reduce-tillage-js": () => import("./../src/pages/soil/soilFertilityProductivityAndHealth/managementPractices/reduceTillage.js" /* webpackChunkName: "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-reduce-tillage-js" */),
  "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-rotate-crops-js": () => import("./../src/pages/soil/soilFertilityProductivityAndHealth/managementPractices/rotateCrops.js" /* webpackChunkName: "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-rotate-crops-js" */),
  "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-use-cover-crops-js": () => import("./../src/pages/soil/soilFertilityProductivityAndHealth/managementPractices/useCoverCrops.js" /* webpackChunkName: "component---src-pages-soil-soil-fertility-productivity-and-health-management-practices-use-cover-crops-js" */),
  "component---src-pages-soil-soil-fertility-productivity-and-health-soil-fertility-and-productivity-js": () => import("./../src/pages/soil/soilFertilityProductivityAndHealth/soilFertilityAndProductivity.js" /* webpackChunkName: "component---src-pages-soil-soil-fertility-productivity-and-health-soil-fertility-and-productivity-js" */),
  "component---src-pages-soil-soil-fertility-productivity-and-health-soil-health-js": () => import("./../src/pages/soil/soilFertilityProductivityAndHealth/soilHealth.js" /* webpackChunkName: "component---src-pages-soil-soil-fertility-productivity-and-health-soil-health-js" */),
  "component---src-pages-soil-soil-properties-anion-exchange-capacity-js": () => import("./../src/pages/soil/soilProperties/anionExchangeCapacity.js" /* webpackChunkName: "component---src-pages-soil-soil-properties-anion-exchange-capacity-js" */),
  "component---src-pages-soil-soil-properties-available-water-js": () => import("./../src/pages/soil/soilProperties/availableWater.js" /* webpackChunkName: "component---src-pages-soil-soil-properties-available-water-js" */),
  "component---src-pages-soil-soil-properties-cation-exchange-capacity-js": () => import("./../src/pages/soil/soilProperties/cationExchangeCapacity.js" /* webpackChunkName: "component---src-pages-soil-soil-properties-cation-exchange-capacity-js" */),
  "component---src-pages-soil-soil-properties-field-capacity-js": () => import("./../src/pages/soil/soilProperties/fieldCapacity.js" /* webpackChunkName: "component---src-pages-soil-soil-properties-field-capacity-js" */),
  "component---src-pages-soil-soil-properties-soil-electrical-conductivity-js": () => import("./../src/pages/soil/soilProperties/soilElectricalConductivity.js" /* webpackChunkName: "component---src-pages-soil-soil-properties-soil-electrical-conductivity-js" */),
  "component---src-pages-soil-soil-properties-soil-ph-js": () => import("./../src/pages/soil/soilProperties/soilPH.js" /* webpackChunkName: "component---src-pages-soil-soil-properties-soil-ph-js" */),
  "component---src-pages-soil-soil-properties-soil-texture-js": () => import("./../src/pages/soil/soilProperties/soilTexture.js" /* webpackChunkName: "component---src-pages-soil-soil-properties-soil-texture-js" */),
  "component---src-pages-soil-soil-properties-soil-water-relations-js": () => import("./../src/pages/soil/soilProperties/soilWaterRelations.js" /* webpackChunkName: "component---src-pages-soil-soil-properties-soil-water-relations-js" */),
  "component---src-pages-soil-soil-properties-wilting-point-js": () => import("./../src/pages/soil/soilProperties/wiltingPoint.js" /* webpackChunkName: "component---src-pages-soil-soil-properties-wilting-point-js" */),
  "component---src-pages-soil-soil-types-alluvial-soils-js": () => import("./../src/pages/soil/soilTypes/alluvialSoils.js" /* webpackChunkName: "component---src-pages-soil-soil-types-alluvial-soils-js" */),
  "component---src-pages-soil-soil-types-arid-desert-soils-js": () => import("./../src/pages/soil/soilTypes/aridDesertSoils.js" /* webpackChunkName: "component---src-pages-soil-soil-types-arid-desert-soils-js" */),
  "component---src-pages-soil-soil-types-black-soils-js": () => import("./../src/pages/soil/soilTypes/blackSoils.js" /* webpackChunkName: "component---src-pages-soil-soil-types-black-soils-js" */),
  "component---src-pages-soil-soil-types-forest-mountain-soils-js": () => import("./../src/pages/soil/soilTypes/forestMountainSoils.js" /* webpackChunkName: "component---src-pages-soil-soil-types-forest-mountain-soils-js" */),
  "component---src-pages-soil-soil-types-laterite-soils-js": () => import("./../src/pages/soil/soilTypes/lateriteSoils.js" /* webpackChunkName: "component---src-pages-soil-soil-types-laterite-soils-js" */),
  "component---src-pages-soil-soil-types-peaty-and-marshy-soils-js": () => import("./../src/pages/soil/soilTypes/peatyAndMarshySoils.js" /* webpackChunkName: "component---src-pages-soil-soil-types-peaty-and-marshy-soils-js" */),
  "component---src-pages-soil-soil-types-red-soils-js": () => import("./../src/pages/soil/soilTypes/redSoils.js" /* webpackChunkName: "component---src-pages-soil-soil-types-red-soils-js" */),
  "component---src-pages-soil-soil-types-saline-alkaline-soils-js": () => import("./../src/pages/soil/soilTypes/salineAlkalineSoils.js" /* webpackChunkName: "component---src-pages-soil-soil-types-saline-alkaline-soils-js" */),
  "component---src-pages-tomato-js": () => import("./../src/pages/tomato.js" /* webpackChunkName: "component---src-pages-tomato-js" */)
}

